.Flower-Petals {
  position: absolute;
  display: flex;
  height: 500px;
  left: -250px;
}

.Flower-Center {
  position: absolute;
  display: flex;
  width: 200px;
  height: 200px;
  left: -100px;
  align-items: center;
  justify-content: center;
  background: var(--yellow);
  border-radius: 50%;
  border: 4px solid var(--black);
}

.Flower-Center p {
  text-decoration: none;
  color: var(--black);
  font-family: var(--projectFont);
  font-Size: 20px;
  font-weight: bold
}
