.Landing-Wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--brown);
}
.Landing-Wrapper p {
  font-family: var(--projectFont);
  font-weight: bold;
  font-size: 40px;
  color: var(--white);
}

.Computer-Wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Screen {
  position: absolute;
  top: 20px;
  left: 30px;
  height: calc(100% - 40px);

  display: flex;
  align-items: stretch;

  background: var(--black);
  z-index: -100;
}

.Screen-Bar {
  width: 12.5%;
  height: 100%;
}

.Computer-Shadow {
  height: 30px;
  margin-top: 10px;

  border-radius: 50%;
  background-color: var(--black);
  opacity: .2;
}
