.Projects-Wrapper::before {
  overflow: hidden;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 101;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}
.Projects-Wrapper {
  height: 100vh;
  overflow: hidden;
  background: var(--purple);
}

.Plant-Wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.Pot {
  width: 300px
}

.Stem {
  width: 65px;
  outline: 4px solid var(--black);
  background: var(--green);
}
