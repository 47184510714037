:root {
  --black: #000000;
  --blue: #75B9BE;
  --brown: #704E2E;
  --green: #9BC53D;
  --lavender: #6369D1;
  --orange: #E28413;
  --purple: #330036;
  --red: #F45B69;
  --turquoise: #0892A5;
  --yellow: #FFAE03;
  --white: #E0E0E0;
  --projectFont: "Basteleur";
}

body {
  margin: 0;
  overscroll-behavior: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Basteleur;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
