html, body {
  overflow: hidden !important;
}

.About-Wrapper::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 105;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

.About-Wrapper {
  max-width: 100vw;
  height: 100vh;
  background-color: var(--blue);
  overflow: clip;
}

.Screen-Top {
  position: absolute;
  top: -1px;
  left: 0;
  height:50vh;
  width:100vw;
  display: inline-block;
  background: var(--black);
  z-index: 102;
}

.Screen-Bottom {
  position: absolute;
  bottom: -1px;
  left: 0;
  height:50vh;
  width:100vw;
  display: inline-block;
  background: var(--black);
  z-index: 101;
}

.Clouds {
  position: absolute;
  width: 105vw;
  top: calc(-25vw + 54px);
  left: -2.5%;
  z-index: 99;
  overflow: clip;
}

.Sun-Wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: -80px;
  margin: 0 auto;
  width: 240px;
  height: 240px;
}

.Outer-Ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--yellow);
  opacity: .6;
}

.Middle-Ring {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  border-radius: 50%;
  background: var(--yellow);
  opacity: .6;
}

.Inner-Ring {
  position: absolute;
  width: 60%;
  height: 60%;
  left: 20%;
  top: 20%;
  border-radius: 50%;
  background: var(--yellow);
}

.Balloon {
  position: absolute;
  width: 35%;
  bottom: 70%;
  left: 0;
  right: 0;
  margin: auto;
}

.About-Text-Wrapper {
  width: 100%;
  position: absolute;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Banner-Rod {
  display: flex;
  justify-content: center;
  width: 80%;
  height: 20px;
  background: var(--brown);
  outline: 2px solid var(--black);
  border-radius: 5px;
}

.Banner {
  width: 95%;
  align-items: center;
  background: var(--white);
  outline: 2px solid var(--black);
  border-radius: 5px;
}


.About-Text-Content {
  width: 74%;
  margin: 2px 0 0 0;

  text-align: center;
  background: var(--white);
  outline: 2px solid var(--black);
  border-radius: 0 0 5px 5px;
}
.About-Text-Content p {
  padding: 20px;
  font-family: var(--projectFont);
}

.Social-Links {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-around;
  padding-bottom: 40px;
}

.Social-Link {
  width: 10%;
  cursor: pointer;
}
.Social-Link:hover {
  transform: scale(1.1);
}
