.Experience-Wrapper {
  width: 100vw;
  height: 100vh;
  background: var(--lavender);
  overflow: scroll;
}

.Jobs-Wrapper {
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Job {
  width: 400px;
  height: 200px;
  margin: 50px;
  background: white;
  border-radius: 25px;
}

.Job-Logo {
  position: relative;
  top: 50px;
  left: 20px;
  width: 100px;
}

.Job-Description {
  position: relative;
  display: flex;
  top: -60px;
  right: 20px;
  width: 250px;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  font-family: var(--projectFont);
}

.Job-Title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.Job-Duration {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: rgb(0,0,0,0.6);
}

.Bomb {
  width: 200px;
  height: auto;
  align-items: center;
  cursor: pointer;
}

.Bomb:hover {
  transform: scale(1.1);
}
