.Header-Wrapper {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  padding: 10px 0 0 0;
  margin: auto;
  z-index: 100;
  overflow: hidden;
}

.Header-Item {
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-family: var(--projectFont);
}

.Header-Item:hover {
  text-decoration: underline;
}
